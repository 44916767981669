import React from 'react'
import { Box } from '@mui/material'
import TitleHeader from '../TitleHeader/TitleHeader'
import dotGrid from '../../images/icons/dot_grid.svg'
import useMediaQueries from '../../hooks/useMediaQueries'

const PageHeader: React.FC = () => {
  const { isSmall } = useMediaQueries()

  return (
    <Box mt={isSmall ? '175px' : '0px'}>
      <Box
        maxWidth='1400px'
        position='relative'
        m='auto'
        sx={{
          '&::before': {
            content: '""',
            backgroundImage: `url('${dotGrid}')`,
            height: isSmall ? '0px' : '520px',
            width: '520px',
            position: 'absolute',
            top: 0,
            left: '-468px',
            zIndex: -1,
          },
        }}
        px='30px'
      >
        <TitleHeader subtitle='Developer Services' />
      </Box>
    </Box>
  )
}
export default PageHeader
