import React from 'react'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import GatsbyLinkCardAction from '../GatsbyLinkCardAction'

type ServicesCardProps = {
  imageData: IGatsbyImageData | undefined
  title: string | null
  description: string | null
  subheader: string | null
  inProgress: boolean | null
}

const ServicesCard: React.FC<ServicesCardProps> = ({
  imageData,
  title,
  subheader,
  description,
  inProgress,
}) => (
  <Card
    sx={{
      zIndex: 2,
      borderRadius: '18px',
      filter: inProgress ? 'blur(2px)' : 'none',
    }}
  >
    <GatsbyLinkCardAction
      disabled={Boolean(inProgress)}
      sx={{
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: 4,
      }}
      to={`/services/${title}`}
    >
      <CardMedia
        sx={{
          textAlign: 'center',
          flex: '0 1 30%',
        }}
      >
        {imageData && <GatsbyImage image={imageData} alt='' />}
      </CardMedia>
      <CardContent
        sx={{
          textAlign: 'center',
          flex: '1 0 70%',
          px: '28px',
          pb: '52px',
        }}
      >
        <Typography
          pt={2}
          gutterBottom
          variant='h5'
          component='div'
          textAlign='center'
          color='#265790'
          textTransform='uppercase'
          fontWeight={700}
        >
          {title}
        </Typography>
        <Typography
          textTransform='uppercase'
          variant='caption'
          fontSize='14px'
          color='primary'
          textAlign='center'
          pb={1}
        >
          {subheader}
        </Typography>
        <Typography variant='body1' color='text.secondary' textAlign='center'>
          {description}
        </Typography>
      </CardContent>
    </GatsbyLinkCardAction>
  </Card>
)
export default ServicesCard
