import { graphql } from 'gatsby'
import React from 'react'
// import Carousel from 'react-material-ui-carousel'
import {
  Box,
  // Card,
  // CardContent,
  // CardMedia,
  Grid,
  Typography,
} from '@mui/material'
// import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'
import rLogo from '../images/icons/small_linear_r.svg'
import useMediaQueries from '../hooks/useMediaQueries'
import PageHeader from '../components/PageHeader/PageHeader'
import ServicesCard from '../components/ServicesCard/ServicesCard'
import {
  // CarouselBlock,
  sectionHeadingStyles,
} from '../components/CarouselBlock/CarouselBlock'
import ContactForm from '../components/ContactForm/ContactForm'
// import GatsbyLinkCardAction from '../components/GatsbyLinkCardAction'

// eslint-disable-next-line no-undef
const ServicesPage: React.FC<{
  // eslint-disable-next-line no-undef
  data: Queries.ServicesPageQuery
}> = ({ data }) => {
  const { isSmall, theme } = useMediaQueries()
  return (
    <>
      <SEO
        title='Services'
        description='Jarrett Retz of retzdev offers frontend ReactJS development services, writing services, and web application development services.'
      />
      <Layout image={data?.sanityHomePage?.logo?.asset || undefined}>
        <img
          alt='logo'
          src={rLogo}
          style={{
            width: '40vw',
            position: 'absolute',
            right: 0,
            zIndex: -1,
            top: '175px',
            maxHeight: '50vh',
            opacity: isSmall ? 0.2 : 0.6,
          }}
        />
        {/* Basic Header */}
        <PageHeader />
        {/* Four Services Card */}
        <Box maxWidth='1000px' m={isSmall ? '0px 15px' : 'auto'} py='129px'>
          <Grid container columnSpacing={2} rowSpacing={2}>
            {data?.allSanityService?.edges &&
              data?.allSanityService?.edges.map(({ node }) => (
                <Grid key={node.header} item sm={12} md={6}>
                  <ServicesCard
                    title={node.header}
                    subheader={node.subheader}
                    imageData={node.imageMedia?.asset?.gatsbyImageData}
                    description={node.description}
                    inProgress={node.inProgress}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        {/* Portfolio Carousel */}
        {/* <CarouselBlock showRLogo titleWord1='Portfolio' titleWord2='Projects'>
          <Carousel
            height={isSmall ? '1005px' : '333px'}
            stopAutoPlayOnHover
            sx={{
              px: 2,
            }}
          >
            <Box>
              <Grid container columnSpacing={2} rowSpacing={2}>
                {data.allSanityPortfolioItem?.edges &&
                  data.allSanityPortfolioItem?.edges.map(({ node }) => (
                    <Grid item xs={12} md={4}>
                      <Card
                        sx={{
                          margin: 'auto',
                          maxWidth: '500px',
                          display: 'flex',
                          borderRadius: '18px',
                          filter: node?.inProgress ? 'blur(2px)' : 'none',
                        }}
                      >
                        <GatsbyLinkCardAction
                          to={`/portfolio/${node?.slug?.current}` || ''}
                          disabled={Boolean(node?.inProgress)}
                          sx={{
                            padding: '30px',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <CardMedia
                            sx={{
                              flex: '1 0 70%',
                            }}
                          >
                            {node?.displayImage?.asset?.gatsbyImageData && (
                              <GatsbyImage
                                alt=''
                                image={
                                  node?.displayImage?.asset?.gatsbyImageData
                                }
                              />
                            )}
                          </CardMedia>
                          <CardContent
                            sx={{
                              flex: '1 0 30%',
                              pt: '32px',
                            }}
                          >
                            <Typography
                              textAlign='center'
                              variant='caption'
                              component='div'
                              color='#265790'
                              textTransform='uppercase'
                              fontWeight={700}
                            >
                              {node?.title}
                            </Typography>
                          </CardContent>
                        </GatsbyLinkCardAction>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Carousel>
        </CarouselBlock> */}
        {/* Contact Form */}
        <Box
          m='auto'
          maxWidth='1400px'
          py={15}
          px='15px'
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Typography
            color='primary'
            borderBottom={`1px solid ${theme.palette.secondary.dark}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...sectionHeadingStyles(isSmall)}
          >
            Get In Touch!
          </Typography>
          <ContactForm />
        </Box>
      </Layout>
    </>
  )
}

export default ServicesPage

export const query = graphql`
  query ServicesPage {
    site {
      siteMetadata {
        title
      }
    }
    sanityHomePage {
      logo {
        asset {
          gatsbyImageData(width: 306, height: 100, placeholder: BLURRED)
        }
      }
    }
    allSanityService(sort: { fields: rank }) {
      edges {
        node {
          _rawOtherMedia(resolveReferences: { maxDepth: 10 })
          inProgress
          description
          header
          subheader
          imageMedia {
            asset {
              gatsbyImageData(height: 300, placeholder: BLURRED)
            }
          }
          otherMedia {
            _rawChildren
          }
        }
      }
    }
    allSanityPortfolioItem {
      edges {
        node {
          inProgress
          slug {
            current
          }
          displayImage {
            asset {
              gatsbyImageData(fit: CLIP, height: 200)
            }
          }
          link
          title
        }
      }
    }
  }
`
